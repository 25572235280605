import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { BRMColumn } from "../brm/columns";
import { ScrollingModule } from "@angular/cdk/scrolling";

@Component({
  selector: "app-mine-brmtable",
  templateUrl: "./mine-brmtable.component.html",
  styleUrls: ["./mine-brmtable.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MineBRMTableComponent implements OnInit {
  @Input()
  columns = [] as { visible: boolean; column: BRMColumn; width: number }[];
  @Input() data: any[] = [];
  @Input() isEditingEnabled = false;

  @Input() paginator = false;

  @Input() approveChangesIsEnabled = false;
  @Output() filterReset: EventEmitter<any> = new EventEmitter();
  @Output() filterChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() ruleChanged: EventEmitter<any> = new EventEmitter();

  @Input() primary_key = [] as string[];
  @Input() changeLog: any = {};

  allSelection = false;

  filterChanged(event: any, column: any) {
    this.filterChangedEvent.emit({ event, column });
  }

  sorterChanged(event: any, column: any) {}

  currentTableMode() {
    return "edit";
  }

  allRowSelectionChanged(event: any) {}

  ngOnInit() {}

  all_rows_selected = true;

  validateInsert(rule: any) {
    if (rule["metadata"] === undefined) return true;
    return Object.keys(rule)
      .map((key: string) => {
        return rule[key] !== "";
      })
      .some((state: boolean) => state);
  }

  getVisibleHeaders() {
    return this.columns.filter((header: any) => header.visible);
  }

  trackByMolecule(_index: number, row: { molecule: string }) {
    return row.molecule;
  }

  newChange(changeInfo: any) {
    this.ruleChanged.emit(changeInfo);
  }
}

function createArray(N: number) {
  const array = [];
  for (let i = 1; i <= N; i++) {
    array.push(i);
  }
  return array;
}
