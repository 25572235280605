<data-source-dialog
    (sourceUpdated)="refreshComponent()"
    #editDialog
></data-source-dialog>
<div class="card flex justify-content-center">
    <p-toast />
</div>
<div id="wrapper">
    <div class="page_header">
        <app-sub-menu [displayName]="this.getSourceName()"></app-sub-menu>
        <div id="heading">
            <h1>{{ getSourceCountry() }}-{{ this.currentSource?.panel }}</h1>
            <div (click)="openEditSourceModal()" class="edit_button clickable">
                <img src="assets/icons/edit.svg" />
            </div>
            <div id="state">
                <img src="assets/icons/live_status.svg" />
                <ng-container *ngIf="this.currentSource">
                    {{
                        formatProperly(
                            capitalizeFirstLetter(this.currentSource?.status)
                        )
                    }}
                </ng-container>
            </div>
        </div>
        <div id="dates">
            <div>
                <span
                    >Created: {{ getFormattedCreationDate() }} by
                    {{ this.creator_full_name }}</span
                >
                <img src="assets/icons/point.svg" />
                <span *ngIf="this.updater_full_name"
                    >Last Modified: {{ getFormattedUpdateDate() }} by
                    {{ this.updater_full_name }}</span
                >
            </div>
            <div id="actions_button">
                <p-dropdown
                    class="white_button"
                    [options]="action_options"
                    (onChange)="newActionSelected($event)"
                >
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                        <div class="actions_default">
                            <p>Actions</p>
                            <img
                                src="assets/icons/actions_icon.svg"
                                class="actions_icon"
                            />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="item" let-item>
                        <p [ngClass]="{ 'red-item': item.isDelete }">
                            {{ item.label }}
                        </p>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <refresh-data-source
            [currentSource]="this.currentSource"
        ></refresh-data-source>
    </div>
    <div id="loading" *ngIf="!this.content_loaded">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div id="page_content">
        <div id="details_content" style="margin-top: 100px">
            <div class="details_group">
                <h2>General</h2>
                <div class="details">
                    <div>
                        <h3>Countries</h3>
                        <img
                            *ngIf="this.currentSource"
                            src="assets/icons/countries/{{
                                this.currentSource.country
                            }}.svg"
                            class="country_icon"
                        />
                        <p style="display: inline">{{ getSourceCountry() }}</p>
                    </div>
                    <div>
                        <h3>Data Vendor</h3>
                        <p>{{ getDataVendor() }}</p>
                    </div>
                    <div>
                        <h3>Panel Group</h3>
                        <p>{{ getPanelGroup() }}</p>
                    </div>
                    <div>
                        <h3>Panel Name</h3>
                        <p>{{ this.currentSource?.panel }}</p>
                    </div>
                    <div>
                        <h3>Scope of Subscription</h3>
                        <p>
                            {{ getScopeOfSubscription() }}
                            <img
                                src="assets/icons/tooltip_icon.svg"
                                style="padding-left: 6px"
                                [pTooltip]="
                                    this.getScopeOfSubscriptionTooltip()
                                "
                                *ngIf="
                                    this.currentSource &&
                                    this.currentSource.scope_of_subscription &&
                                    this.currentSource.scope_of_subscription.toLowerCase() !==
                                        'full'
                                "
                            />
                        </p>
                    </div>
                    <div>
                        <h3>Contact Owner</h3>
                        <p>
                            {{ this.currentSource?.contact_owner_name }}
                            <img
                                src="assets/icons/tooltip_icon.svg"
                                style="padding-left: 7.33px"
                                [pTooltip]="
                                    this.getContactOwnerToolTipContent()
                                "
                            />
                        </p>
                    </div>
                </div>
            </div>

            <div class="details_group">
                <h2>Panel Content</h2>
                <div class="details two_columns">
                    <div>
                        <h3>Data Type</h3>
                        <p>{{ getDataType() }}</p>
                    </div>
                    <div>
                        <h3>Granularity</h3>
                        <p>{{ getGranularity() }}</p>
                    </div>
                    <div>
                        <h3>Channel/Supply Chain</h3>
                        <p>{{ getChannel() }}</p>
                    </div>
                    <div>
                        <h3>Panel Coverage</h3>
                        <p *ngIf="this.currentSource">
                            {{ this.currentSource?.panel_coverage }} %
                        </p>
                    </div>
                </div>
            </div>
            <div class="details_group">
                <h2>Product Classification</h2>
                <div class="details">
                    <div>
                        <h3>Formulation Class Type</h3>
                        <p>{{ getFormulationClassType() }}</p>
                    </div>
                    <div>
                        <h3>Therapy Class Type</h3>
                        <p>{{ getClassType() }}</p>
                    </div>
                    <div>
                        <h3>Volume Units</h3>
                        <p>
                            {{ getVolumeUnits() }}
                            <img
                                *ngIf="
                                    getVolumeUnits() && !isSingleVolumeUnit()
                                "
                                src="assets/icons/tooltip_icon.svg"
                                style="padding-left: 7.33px"
                                [pTooltip]="this.getVolumeUnitsTooltip()"
                            />
                        </p>
                    </div>
                </div>
            </div>
            <div class="details_group">
                <h2>Sales Price</h2>
                <div class="details two_columns">
                    <div>
                        <h3>Price Level</h3>
                        <p>{{ getPriceLevel() }}</p>
                    </div>
                    <div>
                        <h3>Currency</h3>
                        <p>{{ getCurrency() }}</p>
                    </div>
                </div>
            </div>
            <div id="time_panel" class="details_group">
                <h2>Time</h2>
                <div class="details">
                    <div>
                        <h3>Frequency:</h3>
                        <p>{{ getUpdateFrequency() }}</p>
                    </div>
                    <div>
                        <h3>Start period:</h3>
                        <p>{{ getFormattedStartPeriod() }}</p>
                    </div>
                    <div>
                        <h3>Current update:</h3>
                        <p>{{ getFormattedCurrentUpdate() }}</p>
                    </div>
                    <div></div>
                    <div>
                        <h3>Back Date by update:</h3>
                        <p>{{ getFormattedBackDate() }}</p>
                    </div>
                    <div>
                        <h3>End period:</h3>
                        <p>{{ getFormattedEndPeriod() }}</p>
                    </div>
                    <div>
                        <h3>Next update:</h3>
                        <p>{{ getFormattedNextUpdate() }}</p>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
        <div id="charts_and_tables">
            <div id="attributes_information">
                <h2>Attributes Information</h2>
                <div id="attributes_container">
                    <div>
                        <app-mine-table
                            [headers]="attributes_information_headers"
                            [isKPITable]="true"
                            [data]="attributes_information"
                            (rowClicked)="openAttributeCharts($event)"
                        ></app-mine-table>
                    </div>
                </div>
            </div>
            <div
                id="attribute_charts"
                *ngIf="attribute_charts_open && this.displayAttributesChart()"
            >
                <app-attributes-charts
                    [current_records_data]="attributes_charts_current_records"
                    [unique_values_data]="attributes_charts_unique_values"
                    [percent_blanks_data]="attributes_charts_percent_blanks"
                    [field_name]="this.current_field_name"
                    [showByUpdate]="false"
                ></app-attributes-charts>
            </div>
            <div id="sales_information">
                <h2>Sales Information</h2>
                <div id="sales_information_table">
                    <app-mine-table
                        [data]="sales_information"
                        [headers]="sales_information_headers"
                        (rowClicked)="salesRowClicked($event)"
                    ></app-mine-table>
                </div>
                <p
                    class="no_data_message"
                    style="height: 423px"
                    *ngIf="
                        !sales_informations_chart_data ||
                        sales_informations_chart_data.datasets.length === 0 ||
                        !sales_informations_chart_data.datasets[0].data ||
                        sales_informations_chart_data.datasets[0].data
                            .length === 0 ||
                        this.allZeros(sales_informations_chart_data.datasets[0])
                    "
                >
                    No Data Available
                </p>
                <p-card
                    [style]="{ height: 'auto' }"
                    *ngIf="
                        sales_informations_chart_data &&
                        sales_informations_chart_data.datasets.length > 0 &&
                        sales_informations_chart_data.datasets[0].data &&
                        sales_informations_chart_data.datasets[0].data.length >
                            0
                    "
                >
                    <p-chart
                        *ngIf="
                            sales_informations_chart_data &&
                            sales_informations_chart_data.datasets.length > 0 &&
                            sales_informations_chart_data.datasets[0].data &&
                            sales_informations_chart_data.datasets[0].data
                                .length > 0
                        "
                        #sales_information_chart
                        type="line"
                        [data]="sales_informations_chart_data"
                        [options]="sales_informations_chart_options"
                        [plugins]="plugins"
                        height="350px"
                    ></p-chart>
                </p-card>
            </div>
            <div id="data_delivery">
                <h2>Data Delivery</h2>
                <app-mine-table
                    [data]="this.data_delivery_data"
                    [headers]="this.data_delivery_headers"
                >
                </app-mine-table>
                <div
                    class="card"
                    id="delays_chart"
                    *ngIf="this.displayDelaysChart()"
                >
                    <p-card>
                        <h3>Delays</h3>
                        <app-bars-chart
                            [delays_data]="delays_data"
                        ></app-bars-chart>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
</div>
