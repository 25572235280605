import { toFont, toPercentage } from "chart.js/helpers";
import { spaceDecimalsInSalesKPIs } from "./source-details.component";
import { replaceAll } from "../core/models/helpers";

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title: string) => {
      const tr = document.createElement("tr") as any;
      tr.style.borderWidth = 0;

      const th = document.createElement("th") as any;
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr") as any;
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td") as any;
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    if (tableRoot) {
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

export const externalCarry = (
  data: any[],
  salesEvolutionPerMetric = {} as Record<string, Record<string, any>>,
  currentSale: string,
) => {
  return (context: any) => {
    if (!context) return;

    console.log("this is the context ", context);
    const currentEndPeriod = context.tooltip.title[0];
    const currentValue = context.tooltip.dataPoints[0].raw;
    console.log(
      "value is ",
      currentValue,
      " and evolution is ",
      salesEvolutionPerMetric[currentSale][currentEndPeriod],
    );

    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    function getBody(bodyItem: any) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      console.log(
        "tooltip info = ",
        tooltipModel.title,
        "   ",
        tooltipModel.body,
      );
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = "<thead>";

      /*titleLines.forEach(function (title: string) {
        innerHtml += "<tr><th>" + "toutou" + "</th></tr>";
      });
      innerHtml += "</thead><tbody>";

      bodyLines.forEach(function (body: any, i: number) {
        const colors = tooltipModel.labelColors[i];
        let style = "background:" + colors.backgroundColor;
        style += "; border-color:" + colors.borderColor;
        style += "; border-width: 2px";
        const span = '<span style="' + style + '">' + body + "</span>";
        innerHtml += "<tr><td>" + span + "</td></tr>";
      });
      innerHtml += "</tbody>";*/

      let nf = new Intl.NumberFormat("en-US");
      const formatNumber = (n: any) => {
        return replaceAll(nf.format(n), ",", " ");
      };

      const endPeriod = currentEndPeriod;
      const unit = currentSale;
      const number = formatNumber(currentValue);

      const rawEvolution =
        salesEvolutionPerMetric[currentSale][currentEndPeriod];
      let evolution = undefined;
      if (rawEvolution !== undefined) {
        let sign = "";

        let n = rawEvolution;
        if (n !== 0) {
          sign = n > 0 ? "+" : "-";
        }

        evolution =
          "(" +
          sign +
          formatFloatToTwoDecimals(
            salesEvolutionPerMetric[currentSale][currentEndPeriod],
          ) +
          "%)";
      } else evolution = "";

      const currency = "$";

      const style = `
        .grid-container {
          display: grid;
          grid-template-columns: 1fr 20px 1fr;
        }

        .grid-container > * {

        }

        .tooltip_container {
            padding-block: 10px;
            padding-inline: 8px;

            box-shadow: 0px 8px 20px 0px #05101814;
            border: 1px solid #ebeced;
            border-radius: 8px;
            background-color: #f0f1f1cc;
        }
        .tooltip_container img{
        //border: solid black 1px;
        }
        `;
      innerHtml = `<div class='tooltip_container grid-container'>
        <style>
          ${style}
        </style>
          <b>${endPeriod}</b>
          <img src='assets/icons/tooltip_icon.png' style='width: 8px; height:8px;'/>
          <p>${unit}</p>
          <p></p>
          <p>${currency}</p>
          <p>${number} ${evolution}</p>
        </div>`;

      let tableRoot = tooltipEl.querySelector("table");
      if (tableRoot) tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    const bodyFont = toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + "px " + tooltipModel.padding + "px";
    tooltipEl.style.pointerEvents = "none";
  };
};
function formatFloatToTwoDecimals(floatNumber: number) {
  return floatNumber.toFixed(2);
}
