import { NgTemplateOutlet } from "@angular/common";
import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-mine-accordion",
  templateUrl: "./mine-accordion.component.html",
  styleUrls: ["./mine-accordion.component.scss"],
})
export class MineAccordionComponent implements OnInit {
  getColor(update: any): any {
    if (update.completion_date) {
      return "#35BA55";
    } else return "#8E9397";
  }
  getIcon(update: any): any {
    if (update.completion_date) {
      return "assets/icons/update/completed.svg";
    } else return "assets/icons/update/not_completed.svg";
  }
  toggle() {
    this.expanded = !this.expanded;
  }
  getTogglerIcon() {
    if (this.expanded) return "assets/icons/accordion_up.svg";
    return "assets/icons/accordion_down.svg";
  }
  ngOnInit(): void {
    console.log("other side, ", this.update);
  }

  getDatasetsLabel() {
    if (this.update.number_of_datasets > 1)
      return `${this.update.number_of_datasets} datasets`;
    else return `1 dataset`;
  }

  getDate(update: any) {
    if (isNaN(update.batch_id)) {
      return "Next Update";
    }

    if (update.completion_date)
      return "Completed: " + this.formatDate(update.completion_date);
    else if (update.start_date)
      return "Started: " + this.formatDate(update.start_date);

    return "Next Update";
  }

  formatDate(date: Date) {
    return `${this.addLeadingZero(date.getDate())}.${this.addLeadingZero(
      date.getMonth() + 1,
    )}.${this.addLeadingZero(date.getFullYear())} at ${this.formatTime(date)} ${this.formatTimeZoneOffset(date)}`;
  }

  formatTimeZoneOffset(date: Date) {
    const offsetInMinutes = moment(date).utcOffset();
    const offsetInHours = Math.floor(offsetInMinutes / 60);

    if (offsetInHours > 0)
      return `(UTC+${this.addLeadingZero(offsetInHours)}:00)`;
    else return `(UTC-${this.addLeadingZero(Math.abs(offsetInHours))}:00)`;
  }

  formatTime(date: Date) {
    const tokens = date.toLocaleTimeString("en-US").split(" ");
    const time = tokens[0];
    const timeTokens = time.split(":");
    const withoutSecondsTime = timeTokens[0] + ":" + timeTokens[1];

    return withoutSecondsTime + " " + tokens[1];
  }

  addLeadingZero(number: number) {
    return number < 10 ? `0${number}` : number.toString();
  }

  getBatchLabel(update: any) {
    if (isNaN(update.batch_id)) {
      return "Next Update";
    } else return `Update #${update.batch_id}`;
  }

  @Input() expanded = true;
  @Input() template: TemplateRef<any> | null = null;
  @Input() update: any | undefined;
}
