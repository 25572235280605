<div style="margin-top: 48px">
    <div id="btm_table_wrapper">
        <cdk-virtual-scroll-viewport
            itemSize="50"
            style="height: 800px"
            class="virtual_scroller"
            *ngIf="this.data.length > 0"
        >
            <table id="brm_table">
                <thead>
                    <tr>
                        <th
                            *ngFor="let col of this.columns"
                            style="width: {{ col.width }}px;"
                        >
                            <div
                                class="brm_header"
                                [ngClass]="{
                                    selection_column:
                                        col === this.getVisibleHeaders()[0],
                                }"
                            >
                                <div
                                    class="brm_header_text"
                                    [ngClass]="{
                                        selection_column:
                                            col === this.getVisibleHeaders()[0],
                                    }"
                                >
                                    <ng-container
                                        *ngIf="
                                            col === this.getVisibleHeaders()[0]
                                        "
                                    >
                                        <p-checkbox
                                            [binary]="true"
                                            (onChange)="
                                                allRowSelectionChanged($event)
                                            "
                                            [(ngModel)]="this.allSelection"
                                            *ngIf="
                                                (this.isEditingEnabled &&
                                                    this.currentTableMode() ===
                                                        'edit') ||
                                                this.approveChangesIsEnabled
                                            "
                                            [(ngModel)]="this.all_rows_selected"
                                        ></p-checkbox>
                                    </ng-container>
                                    <p>{{ col.column.getHeader() }}</p>
                                </div>
                                <img
                                    class="clickable"
                                    src="assets/icons/filter_trigger_brm.svg"
                                    (click)="op.toggle($event)"
                                />
                                <p-overlayPanel #op>
                                    <app-brm-column-filter
                                        [column]="col.column"
                                        (filterChanged)="
                                            this.filterChanged(
                                                $event,
                                                col.column
                                            )
                                        "
                                        (sorterChanged)="
                                            this.sorterChanged(
                                                $event,
                                                col.column
                                            )
                                        "
                                        (filterReset)="
                                            this.filterReset.emit($event)
                                        "
                                    />
                                </p-overlayPanel>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *cdkVirtualFor="let row of this.data">
                        <!-- <td *ngFor="let column of this.columns">
                            {{ row[column.column.getField()] }}
                        </td> -->
                        <td *ngFor="let column of this.columns">
                            <app-brm-cell-editor
                                [column]="column.column"
                                [displayValue]="row"
                                (newChange)="this.newChange($event)"
                                [editingState]="this.isEditingEnabled"
                                [primary_key]="this.primary_key"
                                [width]="column.width"
                            >
                            </app-brm-cell-editor>
                        </td>
                    </tr>
                </tbody>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
