import { Component, OnInit, ViewChild } from "@angular/core";
import { SourceService } from "../core/services/source.service";
import * as moment from "moment";
import { CustomLegendPlugin, legendColors } from "./custom-legend-plugin";
import { ConfirmationService, MessageService } from "primeng/api";
import { getCountryName } from "../core/models/country_codes";
import { ActivatedRoute, Router } from "@angular/router";
import {
  getChannelLabel,
  getCurrencyLabel,
  getDataTypeLabel,
  getDataVendorLabel,
  getFormulationClassTypeLabel,
  getGranularityLabel,
  getPanelGroupLabel,
  getPriceLevelLabel,
  getScopeOfSubscriptionLabel,
  getTherapyClassTypeLabel,
  getUpdateFrequencyLabel,
  getVolumeUnitsListLabels,
  update_frequency_units,
} from "../landing/my/filters-data";
import { DataSourceDialogComponent } from "../data-source-dialog/data-source-dialog.component";
import { UIChart } from "primeng/chart";
import { buildChartData } from "../bars-chart/bars_chart_utils";
import { convertUTCToLocal } from "../core/utils/dateTime";
import {
  capitalizeFirstLetter,
  delays_chart_options,
  replaceAll,
} from "../core/models/helpers";
import {
  attributes_information_headers,
  last_n_batchs,
} from "../core/utils/KPIUtils";
import { computeChartSettings } from "../bars-chart/bars_chart_utils";
import { buildYaxisRenderingLambda } from "../attributes-charts/attributes-charts.component";
import { externalCarry, externalTooltipHandler } from "./tooltip";

@Component({
  selector: "app-source-detail",
  templateUrl: "./source-details.component.html",
  styleUrls: ["./source-details.component.scss"],
  providers: [MessageService],
})
export class SourceDetailComponent implements OnInit {
  @ViewChild(DataSourceDialogComponent)
  editDialog: DataSourceDialogComponent | null = null;

  @ViewChild("sales_information_chart", { static: false })
  sales_information_chart: UIChart | null = null;

  @ViewChild("delays_chart", { static: false })
  delays_chart: UIChart | null = null;

  content_loaded = false;

  attributes_information: any[] = [];
  sales_information: any[] = [];

  attributes_charts_unique_values = {
    labels: [] as string[],
    datasets: [] as any[],
  };

  attributes_charts_current_records = {
    labels: [] as string[],
    datasets: [] as any[],
  };

  attributes_charts_percent_blanks = {
    labels: [] as string[],
    datasets: [] as any[],
  };

  attribute_chart_data_point_template = {
    fill: false,
    borderColor: "#35BA55",

    pointStyle: "circle",

    radius: 7,
    pointBackgroundColor: "white",
    pointBorderWidth: 1.5,

    hoverRadius: 7,
    pointHoverBackgroundColor: "white",
    pointHoverBorderWidth: 1.5,

    borderWidth: 1.5,
  };

  plugins = [new CustomLegendPlugin()];

  sales_information_dataset = {
    label: "Local currency",
    data: [] as any[],
    fill: false,
    borderColor: "#F43636",

    pointStyle: "circle",

    radius: 7,
    pointBackgroundColor: "white",
    pointBorderWidth: 1.5,

    hoverRadius: 7,
    pointHoverBackgroundColor: "white",
    pointHoverBorderWidth: 1.5,

    borderWidth: 1.5,
  };

  jsonify(object: any) {
    return JSON.stringify(object);
  }
  sales_informations_chart_data = {
    labels: [] as any[],
    datasets: [] as any[],
  };

  sales_informations_chart_options = {};

  delays_chart_options = delays_chart_options;

  delays_data = {
    labels: [],
    datasets: [] as any[],
  };

  creator_full_name: string | undefined;
  updater_full_name: string | null = null;

  attributes_information_headers = attributes_information_headers;

  sales_information_headers = [
    { name: "Sales Metric", key: "sales" },
    { name: "Total Sales (Latest Period)", key: "latest_sales_sum" },
    { name: "Current Total Sales VS Previous File", key: "total_sales_sum" },
    {
      name: "Latest Period VS Previous Period",
      key: "sales_sum_rolling_1_period_percent",
    },
    {
      name: "Latest Sales VS Previous Month",
      key: "sales_sum_rolling_1_month_percent",
    },
    {
      name: "Latest Sales VS 3 Previous Month",
      key: "sales_sum_rolling_3_months_percent",
    },
    {
      name: "Latest Sales Rolling 12 Month",
      key: "sales_sum_rolling_12_months_percent",
    },
  ];

  data_delivery_headers = [
    { name: "End Period", key: "latest_end_period" },
    { name: "Delivery Date", key: "latest_delivery_date" },
    {
      name: "Latest Delivery VS Period (Delays)",
      key: "latest_delivery_delay",
    },
    {
      name: "Average Delay in the Last 3 Months",
      key: "last_3_months_avg_delay",
    },
    {
      name: "Average Delay in the Last 12 Months",
      key: "last_12_months_avg_delay",
    },
  ];

  data_delivery_data = [];
  action_options = [] as any;

  currentSource: any;
  current_field_name = "";
  attribute_charts_open = false;
  initialDatasets: any[] = [];
  new_labels: Record<string, string[]> = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private sourceService: SourceService,
    private confirmationService: ConfirmationService,
    private route: Router,
    private messageService: MessageService,
  ) {}

  formatProperly = function (toFormat: string) {
    return toFormat.replace("_", " ");
  };

  salesEvolutionPerMetric = {} as Record<string, Record<string, any>>;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      const source_id = params.get("source_id");

      if (source_id !== null)
        this.sourceService.getSourceById(source_id).subscribe((res) => {
          this.currentSource = res.data;
          console.log("source status", this.currentSource.status);
          this.creator_full_name = res.created_by_full_name;
          this.updater_full_name = res.updated_by_full_name;

          if (this.editDialog) {
            this.editDialog.source = res.data;
          }
          this.content_loaded = true;

          this.action_options = this.getActionOptions();

          this.sourceService
            .getAttributeInformation(this.getSourceName())
            .subscribe((attributes_information) => {
              this.attributes_information = this.reorderAttributesTable(
                attributes_information.map((attribute_informations: any) => ({
                  field: attribute_informations.field_name.split(".")[1],
                  ...attribute_informations,
                })),
              );
              console.log(this.attributes_information);

              this.openAttributeCharts({ field: "product" });
            });

          this.sourceService
            .getSalesInformation(this.getSourceName())
            .subscribe((res) => {
              console.log("hello here,", res.sales_kpis);
              res.sales_kpis = res.sales_kpis.map((KPI: any) =>
                spaceDecimalsInSalesKPIs(
                  replaceKeyWithDash(KPI, keysToConvert, [0.0, "NaN"]),
                  keysToConvert,
                ),
              );
              console.log("new KPIs, ", res.sales_kpis);
              this.sales_information = this.reOrderSales(
                res.sales_kpis.map((sales_data: any) => ({
                  ...sales_data,
                  sales: sales_data.sales.replace("Sales in ", ""),
                })),
              );

              const new_datasets: any[] = [];

              const salesEvolutionPerMetric = {} as Record<
                string,
                Record<string, any>
              >;

              res.sales_kpis.map((information: any, index: number) => {
                const label = information.sales.replace("Sales in ", "");
                const new_data_set = { ...this.sales_information_dataset };
                new_data_set.label = label;
                new_data_set.borderColor =
                  legendColors[index % legendColors.length];
                const sale_history = Object.keys(res.total_sales_history).find(
                  (sale: string) => sale === information.sales,
                );
                const orderByEndPeriod = (objectA: any, objectB: any) =>
                  objectA.end_period > objectB.end_period ? 1 : -1;

                let dates = [];
                let sales_values = [];

                if (sale_history) {
                  console.log("history found ", information.sales);
                  let values = res.total_sales_history[sale_history] as any[];
                  values = values.map((value: any) => ({
                    ...value,
                    end_period: moment(value.end_period, "YYYYMM").toDate(),
                  }));

                  let result = {} as Record<string, number | undefined>;
                  let dataHasEvolution = values.length > 0;
                  let arr = values;
                  arr.sort(orderByEndPeriod);

                  if (dataHasEvolution) {
                    for (let i = 0; i < arr.length; i++) {
                      if (i === 0) {
                        const end_period = this.formatDateForChart(
                          moment(arr[i].end_period, "YYYYMM").toDate(),
                        );
                        result[end_period] = undefined;
                        continue;
                      }

                      //@TODO are sales metric really just integers (or also floats)?
                      const prevValue = parseInt(arr[i - 1].value);
                      const currentValue = parseInt(arr[i].value);
                      let percentageChange = undefined;

                      if (prevValue !== 0)
                        percentageChange =
                          ((currentValue - prevValue) / prevValue) * 100;

                      const end_period = this.formatDateForChart(
                        moment(arr[i].end_period, "YYYYMM").toDate(),
                      );
                      result[end_period] = percentageChange;
                    }
                    console.log("sales evolution is ", result);
                  }
                  salesEvolutionPerMetric[label] = result;
                  console.log();

                  values.sort(orderByEndPeriod);

                  dates = values.map((value: any) => value.end_period);
                  sales_values = values.map((value: any) =>
                    parseInt(value.value),
                  );
                  console.log("values => ", sales_values, " dates =>", dates);
                  new_data_set.data = sales_values;
                  this.new_labels[sale_history] = dates.map((date: Date) =>
                    this.formatDateForChart(date),
                  );

                  new_datasets.push(new_data_set);
                }
              });

              console.log("complete evolution ", salesEvolutionPerMetric);
              this.salesEvolutionPerMetric = salesEvolutionPerMetric;

              this.initialDatasets = new_datasets;
              console.log("intial datasets ", this.initialDatasets);

              const new_chart_data = { ...this.sales_informations_chart_data };
              new_chart_data.datasets = new_datasets;
              this.sales_informations_chart_data = new_chart_data;
              this.sales_information_chart?.refresh();

              this.changeDisplayedSalesChart("Local Currency");
            });

          this.sourceService
            .getDelays(this.getSourceName())
            .subscribe((delays_data) => {
              console.log(delays_data);
              const date_format = "MMMM YYYY";
              let ordered_months = Object.keys(delays_data).map(
                (date: any) => ({
                  parsed: moment(date, date_format).toDate(),
                  original: date,
                }),
              );
              ordered_months = ordered_months.sort((monthA, monthB) =>
                monthA.parsed < monthB.parsed ? -1 : 1,
              );

              let new_labels = [] as any;

              for (const monthIndex in ordered_months) {
                const currentMonth = ordered_months[monthIndex].original;
                new_labels.push(currentMonth);
              }
              if (new_labels.length === 1) {
                new_labels = ["", ...new_labels, ""];
              }

              const new_dataset = buildChartData(
                ordered_months.map(
                  (month: any) =>
                    Object.values(delays_data[month.original])[0] as any[],
                ),
              );

              this.delays_data = {
                labels: new_labels,
                datasets: [new_dataset],
              };

              console.log(this.delays_data);
              //this.delays_chart?.refresh();
            });

          this.sourceService
            .getDataDelivery(this.getSourceName())
            .subscribe((delivery_data) => {
              this.data_delivery_data = delivery_data.map(
                (data_point: any) => ({
                  latest_end_period: moment(data_point.end_period).format(
                    "MM-YYYY",
                  ),
                  latest_delivery_date: moment(
                    data_point.actual_delivery_date,
                  ).format("DD.MM.YYYY"),
                  last_3_months_avg_delay: parseFloat(
                    data_point.last_3_months_avg_delay,
                  ).toFixed(2),
                  last_12_months_avg_delay: parseFloat(
                    data_point.last_12_months_avg_delay,
                  ).toFixed(2),
                  latest_delivery_delay: data_point.delivery_delay,
                }),
              );
              console.log(this.data_delivery_data);
              console.log(delivery_data);
            });
        });
    });
  }

  allZeros(a: { data: number[] }) {
    return a.data.every((item: number) => item === 0);
  }

  formatDateForChart(date: Date): any {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[date.getMonth()].substring(0, 3);
    return `${month} ${date.getFullYear()}`;
  }

  displayAttributesChart() {
    return (
      this.attributes_charts_current_records.datasets.length !== 0 &&
      this.attributes_charts_current_records.datasets[0].data.length !== 0
    );
  }

  getActionOptions() {
    if (this.currentSource) {
      return [
        { label: "Refresh Data Source", value: "refresh" },
        {
          label: "Deactivate Data Source",
          value: "deactivate",
          disabled: this.currentSource.status === "inactive",
        },
        {
          label: "Activate Data Source",
          value: "re-activate",
          disabled: this.currentSource.status === "active",
        },
        { label: "Delete Data Source", value: "delete", isDelete: true },
      ];
    }

    return [
      { label: "Refresh Data Source", value: "refresh" },
      { label: "Deactivate Data Source", value: "deactivate" },
      { label: "Activate Data Source", value: "re-activate" },
      { label: "Delete Data Source", value: "delete", isDelete: true },
    ];
  }

  reOrderSales(salesInformation: any[]) {
    const order = [
      "local currency",
      "unit",
      "standard unit",
      "counting unit",
      "dot",
      "Prescription",
    ];

    const orderedSalesInformation = new Set<any>();

    for (const sale_name of order) {
      const item = salesInformation.find(
        (sales_data) => sales_data.sales.toLowerCase() === sale_name,
      );
      if (item) {
        orderedSalesInformation.add(item);
      }
    }

    //Add any remaining items
    const remaining = salesInformation.filter(
      (sales_data) => !orderedSalesInformation.has(sales_data),
    );
    for (const item of remaining) {
      orderedSalesInformation.add(item);
    }

    return Array.from(orderedSalesInformation);
  }

  salesRowClicked(info: any) {
    const sales = info.sales;
    this.changeDisplayedSalesChart(sales);
  }

  changeDisplayedSalesChart(sales: any) {
    if (this.sales_informations_chart_data.datasets.length === 0) {
      return;
    }

    console.log("displaying sales = ", sales);

    this.plugins[0].setHoveredSales("Sales in " + sales);

    console.log(this.sales_informations_chart_data);

    console.log("initial", this.initialDatasets);
    let new_data_set = this.initialDatasets.find((dataset) => {
      return dataset.label === sales;
    });

    console.log("Found data =", new_data_set);
    let numbersOnly = [];

    if (new_data_set)
      numbersOnly = new_data_set.data.filter(
        (item: any) => typeof item === "number",
      );

    const configuration = computeChartSettings(numbersOnly);

    const newOptions = setStepSize(configuration.step_size, configuration.max);

    newOptions.scales.y.ticks = {
      ...newOptions.scales.y.ticks,
      callback: buildYaxisRenderingLambda(numbersOnly),
    };

    newOptions.plugins.tooltip = {
      ...{
        enabled: false,
        position: "nearest",
        external: externalCarry(
          numbersOnly,
          this.salesEvolutionPerMetric,
          sales,
        ),
      },
    };

    this.sales_informations_chart_options = { ...newOptions };

    console.log("Sales in", this.new_labels["Sales in " + sales]);

    let new_labels = [];
    if (this.new_labels["Sales in " + sales] !== undefined)
      (new_labels = this.new_labels["Sales in " + sales]),
        (this.sales_informations_chart_data = {
          ...this.sales_informations_chart_data,
          labels: new_labels,
        });

    this.sales_informations_chart_data.datasets = [new_data_set];
    console.log("new = ", this.sales_informations_chart_data);
    this.sales_informations_chart_data = {
      ...this.sales_informations_chart_data,
    };

    //this.sales_informations_chart_data.datasets = new_data_sets;
    //this.sales_information_chart?.chart.update();
  }

  mouseLeftSalesRow() {
    this.changeDisplayedSalesChart("Local Currency");
  }

  getSourceName() {
    if (this.currentSource)
      return this.getSourceCountry() + "-" + this.currentSource.panel;

    return "";
  }

  openAttributeCharts(event: any) {
    const field_name = event.field;
    this.current_field_name = field_name;

    this.sourceService
      .getAttributeInformationEvolution(
        this.getSourceCountry() + "-" + this.currentSource.panel,
        field_name.toLowerCase(),
      )
      .subscribe((evolution_data) => {
        console.log("evolution data is", evolution_data);
        let data = last_n_batchs(evolution_data, 6, true).map(
          (data_point: any) => ({
            ...data_point,
            label: data_point.end_period,
          }),
        );
        data = data.sort((data_point_a: any, data_point_b: any) =>
          data_point_a < data_point_b ? 1 : -1,
        );

        console.log("KPI data is", data);
        const labels = data.map((data_point: any) =>
          this.formatDate(data_point.label),
        );

        this.attributes_charts_current_records = {
          labels: labels,
          datasets: [
            {
              ...this.attribute_chart_data_point_template,
              data: data.map((data_point: any) => data_point.current_records),
            },
          ],
        };

        console.log(this.attributes_charts_current_records);

        this.attributes_charts_unique_values = {
          labels: labels as string[],
          datasets: [
            {
              ...this.attribute_chart_data_point_template,
              data: data.map((data_point: any) => data_point.unique_values),
            },
          ],
        };

        this.attributes_charts_percent_blanks = {
          labels: labels as string[],
          datasets: [
            {
              ...this.attribute_chart_data_point_template,
              data: data.map((data_point: any) => data_point.percent_blanks),
            },
          ],
        };
      });
    this.attribute_charts_open = true;
  }

  refreshComponent() {
    this.ngOnInit();
  }

  getSourceCountry() {
    return getCountryName(this.currentSource?.country);
  }

  getDataVendor() {
    if (this.currentSource)
      return getDataVendorLabel(this.currentSource.data_vendor);
    else return "";
  }

  getPanelGroup() {
    if (this.currentSource)
      return getPanelGroupLabel(this.currentSource.panel_group);
    else return "";
  }

  getScopeOfSubscription() {
    if (this.currentSource)
      if (
        this.currentSource.scope_of_subscription &&
        this.currentSource.scope_of_subscription.toLowerCase() !== "full"
      ) {
        return "Partial";
      }
    return "Full";
  }

  getScopeOfSubscriptionTooltip() {
    if (this.currentSource)
      if (this.currentSource.scope_of_subscription.toLowerCase() !== "full") {
        return this.currentSource.scope_of_subscription;
      } else return "";
  }

  getGranularity() {
    if (this.currentSource)
      return getGranularityLabel(this.currentSource.granularity);
    else return "";
  }

  getChannel() {
    if (this.currentSource) return getChannelLabel(this.currentSource.channel);
    else return "";
  }

  getFormulationClassType() {
    if (this.currentSource)
      return getFormulationClassTypeLabel(
        this.currentSource.formulation_class_type,
      );
    else return "";
  }

  getClassType() {
    if (this.currentSource)
      return getTherapyClassTypeLabel(this.currentSource.therapy_class_type);
    else return "";
  }

  getVolumeUnits() {
    if (this.currentSource) {
      const labels = getVolumeUnitsListLabels(this.currentSource.volume_units);
      if (labels.length === 0) return "";
      else if (labels.length === 1) return labels[0];
      else return "Multiple";
    } else return "";
  }

  isSingleVolumeUnit() {
    if (this.currentSource) {
      const labels = getVolumeUnitsListLabels(this.currentSource.volume_units);
      return labels.length === 1;
    }

    return false;
  }

  getVolumeUnitsTooltip() {
    if (this.currentSource) {
      const labels = getVolumeUnitsListLabels(this.currentSource.volume_units);
      return labels.join("\n");
    } else return "";
  }

  getPriceLevel() {
    if (this.currentSource)
      return getPriceLevelLabel(this.currentSource.price_level);
    else return "";
  }

  getCurrency() {
    if (this.currentSource)
      return getCurrencyLabel(this.currentSource.currency);
    else return "";
  }

  getDataType() {
    if (this.currentSource)
      return getDataTypeLabel(this.currentSource.data_type);
    else return "";
  }

  getUpdateFrequency() {
    if (this.currentSource)
      return getUpdateFrequencyLabel(this.currentSource.update_frequency);
    else return "";
  }

  getFormattedStartPeriod() {
    if (this.currentSource) {
      if (
        !this.currentSource.start_date ||
        this.currentSource.start_date === ""
      )
        return "";

      return this.getOnlyMonthYear(new Date(this.currentSource.start_date));
    }

    return "";
  }

  getFormattedCurrentUpdate() {
    if (this.currentSource) {
      if (
        !this.currentSource.current_update ||
        this.currentSource.current_update === ""
      )
        return "";

      return this.formatDate(new Date(this.currentSource.current_update));
    }

    return "";
  }

  getFormattedNextUpdate() {
    if (this.currentSource) {
      if (
        !this.currentSource.next_update ||
        this.currentSource.next_update === ""
      )
        return "";

      return this.formatDate(new Date(this.currentSource.next_update));
    }

    return "";
  }

  getFormattedUpdateDate() {
    if (this.currentSource) {
      if (this.currentSource.updated_at === "") return "";

      return this.formatDate(
        convertUTCToLocal(new Date(this.currentSource.updated_at)),
      );
    }

    return "";
  }

  getFormattedCreationDate() {
    if (this.currentSource) {
      if (this.currentSource.created_at === "") return "";

      return this.formatDate(
        convertUTCToLocal(new Date(this.currentSource.created_at)),
      );
    }

    return "";
  }

  getFormattedEndPeriod() {
    if (this.currentSource) {
      if (
        !this.currentSource.end_period ||
        this.currentSource.end_period === ""
      )
        return "";

      return this.getOnlyMonthYear(new Date(this.currentSource.end_period));
    }

    return "";
  }

  formatDate(date: Date) {
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${
      monthAbbreviations[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }

  private getOnlyMonthYear(date: Date, dash = false) {
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (dash)
      return `${monthAbbreviations[date.getMonth()]}-${date.getFullYear()}`;

    return `${monthAbbreviations[date.getMonth()]} ${date.getFullYear()}`;
  }

  openEditSourceModal() {
    this.confirmationService.confirm({
      header: "Confirmation",
      key: "edit_source_dialog",
      accept: () => {
        alert("yes");
      },
      reject: () => {
        alert("yes");
      },
    });
  }

  getContactOwnerToolTipContent() {
    if (this.currentSource)
      return (
        this.currentSource.contact_owner_name +
        "\n" +
        this.currentSource.contact_owner_email
      );
    else return "";
  }

  getFormattedBackDate() {
    if (this.currentSource) {
      if (this.currentSource.back_data_by_update)
        return (
          this.currentSource.back_data_by_update +
          " " +
          update_frequency_units[this.currentSource.update_frequency]
        );
      else return "";
    } else return "";
  }

  reorderAttributesTable(attribute_information: any[]) {
    const order = [
      "panel",
      "channel",
      "brick",
      "atc4",
      "atc3",
      "corporation",
      "manufacturer",
      "product",
      "molecules",
      "nfc123",
      "pack",
      "gx",
      "rx",
      "volume",
      "strength",
      "launch_date",
    ];

    console.log(attribute_information);
    attribute_information = attribute_information.map((attribute_info) => ({
      ...attribute_info,
      field: attribute_info.field.toLowerCase(),
    }));

    const orderedAttributeInformation = new Set();

    for (const attribute of order) {
      const item = attribute_information.find(
        (attribute_info) => attribute_info.field === attribute,
      );
      if (item) {
        orderedAttributeInformation.add({
          ...item,
          field: capitalizeFirstLetter(attribute),
        });
      }
    }

    //Add any remaining items
    const remaining = attribute_information.filter(
      (attribute_info) => !order.includes(attribute_info.field),
    );

    //Capitalize all
    for (const item of remaining) {
      orderedAttributeInformation.add({
        ...item,
        field: capitalizeFirstLetter(item.field),
      });
    }

    return Array.from(orderedAttributeInformation);
  }

  newActionSelected(event: any) {
    const actionFunctions: Record<string, () => void> = {
      refresh: this.openRefreshSourceModal,
      "re-activate": this.re_activate,
      deactivate: this.de_activate,
      delete: this.delete_current_source,
    };

    const action = event.value;
    actionFunctions[action].call(this);
  }

  openRefreshSourceModal() {
    this.confirmationService.confirm({
      key: "refresh_dialog",
    });
  }

  re_activate() {
    this.sourceService.reactivateSource(this.currentSource.id).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Success",
        detail: "Source re-activated successfully!",
      });
      this.refreshComponent();
    });
  }

  de_activate() {
    this.sourceService.deactivateSource(this.currentSource.id).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Success",
        detail: "Source deactivated successfully!",
      });
      this.refreshComponent();
    });
  }

  delete_current_source() {
    this.sourceService.deleteSource(this.currentSource.id).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Success",
        detail: "Source deleted successfully!",
      });

      setTimeout(() => {
        this.route.navigate([""]);
      }, 2000);
    });
  }
  displayDelaysChart() {
    console.log(this.delays_data.datasets);
    return (
      this.delays_data.datasets.length > 0 &&
      this.delays_data.datasets[0].data.length !== 0
    );
  }

  protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}

export function spaceDecimalsInSalesKPIs(
  obj: Record<string, string>,
  keys: string[],
) {
  let nf = new Intl.NumberFormat("en-US");
  const formatNumber = (n: any) => replaceAll(nf.format(n), ",", " ");

  keys.map((key: string) => (obj[key] = replaceAll(obj[key], "%", "")));

  keys.map((key: string) =>
    !Number.isNaN(parseFloat(obj[key]))
      ? (obj[key] = formatNumber(obj[key]))
      : undefined,
  );

  keys.map((key: string) =>
    key.endsWith("percent") && !Number.isNaN(parseFloat(obj[key]))
      ? (obj[key] = obj[key] + "%")
      : obj[key],
  );
  return obj;
}

export function replaceKeyWithDash(
  obj: Record<string, string>,
  keys: string[],
  toReplace: any[],
) {
  keys.map((key: string) =>
    toReplace.includes(parseFloat(obj[key])) || toReplace.includes(obj[key])
      ? (obj[key] = "-")
      : undefined,
  );

  keys.map((key: string) =>
    obj[key].trim() === "" ? (obj[key] = "-") : undefined,
  );

  return obj;
}

export const keysToConvert = [
  "total_sales_sum",
  "latest_sales_sum",
  "sales_sum_rolling_1_period_percent",
  "sales_sum_rolling_1_month_percent",
  "sales_sum_rolling_3_months_percent",
  "sales_sum_rolling_12_months_percent",
];

export function setStepSize(step_size: number, max: number) {
  const sales_informations_chart_options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        offset: true,
        ticks: {
          padding: 14,
        },
        grid: {
          display: false,
          drawTicks: false,
          ticks: {
            color: "#8E9397",
            font: {
              size: 12,
              family: "Satoshi",
            },
          },
        },
      },
      y: {
        min: 0,
        max: 100,
        border: {
          display: false,
        },
        ticks: {
          stepSize: 10000,
          color: "#8E9397",
          crossAlign: "far",
          font: {
            size: 12,
            family: "Satoshi",
          },
          callback: undefined as undefined | any,
        },
      },
    },
  };

  console.log("new sales step is ", step_size);
  sales_informations_chart_options.scales.y.ticks.stepSize = step_size;
  (sales_informations_chart_options.scales.y.max as number) = max;
  return sales_informations_chart_options;
}
