import { Component } from "@angular/core";
import { stages } from "./stages";
import { SourceService } from "../core/services/source.service";
import * as moment from "moment";
import {
  stepInformation,
  stepState,
  stepsInformation,
} from "../shared/steps-component/stepsInformation";
import { convertUTCToLocal } from "../core/utils/dateTime";
import { reOrderSummary, steps_ordering } from "../core/models/helpers";
import {
  convertAllKPIsToIntegers,
  fixBlanks,
} from "../quality-center-dashboard/quality-center-dashboard.component";
import { map } from "rxjs/operators";
import { convertDateToUTC } from "../update/update.component";
import { getUserRole } from "../dashboard-layout/dashboard-layout.component";
import { AuthService } from "../core/services/auth.service";

@Component({
  selector: "app-home-component",
  templateUrl: "./home-component.component.html",
  styleUrls: ["./home-component.component.scss"],
})
export class HomeComponentComponent {
  time_durations = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "This Week", value: "this_week" },
    { label: "This Month", value: "this_month" },
  ];

  table_data = [] as any[];
  currentPageNumber = 1;
  maxPageNumber = 0;
  perPageNotifications = 20;

  notificationsNavigate(mode: string) {
    if (mode === "previous" && this.currentPageNumber > 1) {
      this.currentPageNumber = this.currentPageNumber - 1;
    } else if (mode === "next" && this.currentPageNumber < this.maxPageNumber) {
      this.currentPageNumber = this.currentPageNumber + 1;
    }
  }

  canNavigate(mode: string) {
    return [
      mode === "previous" && this.currentPageNumber > 1,
      mode === "next" && this.currentPageNumber < this.maxPageNumber,
    ].some((state: boolean) => state);
  }

  stepsState: stepsInformation | undefined;

  initial_notifications = [
    {
      stage: stages.InActive,
      active: true,
    },
  ] as any[];

  notifications = [] as any[];

  notifications_time_filter = "today";
  dataUpdateMetrics = {} as any;
  data_delivery_kpi = {} as any;
  data_library_kpi = {} as any;

  last_completed_batch_id: string | undefined;
  duration_info:
    | Record<string, { created_at?: string; updated_at?: string }>
    | undefined;

  subject: any;
  res: any;
  constructor(
    private sourceService: SourceService,
    private authService: AuthService,
  ) {}

  userFullName() {
    const tokens = this.authService
      .getCurrentUser()
      ["account"]["full_name"].split(" ");
    tokens.pop();
    return tokens.join(" ");
  }

  ngOnInit() {
    this.loadData();

    //TODO move all websocket logic to a service and reuse it
    this.subject = new WebSocket("wss://live.mine.cloud.mangabey.io");

    this.subject.onerror = (event: any) => {
      console.log("error, ", event);
    };
    this.subject.onopen = () => {
      console.log("ws: Websocket connection established.");
    };

    this.subject.onmessage = (event: any) => {
      //TODO make sure you only start updating after initial load is done (and make sure not interleaving updates).
      console.log("ws: Received message:", event.data);
      this.loadData();
    };
  }

  private loadData() {
    this.sourceService.getNotifications().subscribe((notifications: any[]) => {
      this.maxPageNumber = notifications.length / this.perPageNotifications;
      this.initial_notifications = notifications.map(
        (notification: any): any => ({
          active: !notification.resolved,
          originator: notification.originator,
          message: this.capitalizeFirstLetter(notification.notification),
          date: convertUTCToLocal(
            moment(notification.notification_time).toDate(),
          ),
          display_date: this.formatDate(
            convertUTCToLocal(moment(notification.notification_time).toDate()),
          ),
          batch_id: notification.batch_id,
          user_id: notification.user_id,
          user_full_name: notification.user_full_name,
          user_profile_picture: notification.user_profile_picture,
          user_role: getUserRole(notification.user_role),
          raw_data: notification.raw_data,
          step: notification.raw_data ? notification.raw_data.step : undefined,
          stage: notification.status,
        }),
      );
      console.log(this.initial_notifications);
      this.notifications = this.initial_notifications;
      //this.notificationFilterChanged();
    });

    this.sourceService.getDataUpdateDashboardData().subscribe((res) => {
      const data = {} as Record<string, string>;
      res.forEach((kpi: any) => {
        data[kpi.status] = kpi.sources;
      });

      this.dataUpdateMetrics = data;
    });

    this.sourceService.getDataDeliveryDashboardData().subscribe((res) => {
      this.data_delivery_kpi = res;
    });

    this.sourceService.getDataLibraryDashboardData().subscribe((res) => {
      this.data_library_kpi = res;
    });

    this.sourceService
      .getHomeSummary()
      .pipe(
        map((res) => {
          if (res.length > 0) {
            this.last_completed_batch_id = res[0].batch_id;
          }
          const duration_info = {} as Record<
            string,
            { created_at?: string; updated_at?: string }
          >;

          res.map((step_info: any) => {
            duration_info[step_info.batch_step] = {
              created_at: step_info.created_at,
              updated_at: step_info.updated_at,
            };
          });

          this.duration_info = duration_info;

          res = convertAllKPIsToIntegers(res);
          res = fixBlanks(res);
          this.table_data = reOrderSummary(res);
        }),
      )
      .subscribe((res) => {
        this.sourceService.getStatusSummary().subscribe((res) => {
          if (!this.duration_info) return;
          //TODO: consider timezones in duration calculation
          this.res = res[0];
          this.calculateStepsInfo();
          setInterval(() => {
            this.step_info = this.calculateStepsInfo();
            this.currentUpdateStatus = this.calculateCurrentStatus(
              this.step_info,
            );
          }, 1000);
          return;
        });
      });
  }

  isValidDate(d: Date | undefined) {
    return d && !isNaN(d.getDate());
  }
  currentUpdateStatus = "";

  calculateCurrentStatus(stepInfo: stepsInformation) {
    console.log("I am called man!");
    const cleaningIsCompleted = stepInfo.cleaning.state === stepState.completed;
    const transcodingIsCompleted =
      stepInfo.transcoding.state === stepState.completed;

    let ConsoOrLaterIsRunning = false;
    ConsoOrLaterIsRunning =
      stepInfo.consolidation.state === stepState.in_progress ||
      stepInfo.consolidation.state === stepState.completed ||
      stepInfo.categorization.state === stepState.in_progress ||
      stepInfo.categorization.state === stepState.completed ||
      stepInfo.referencing.state === stepState.in_progress ||
      stepInfo.referencing.state === stepState.completed ||
      stepInfo.recoding.state === stepState.in_progress;
    stepInfo.recoding.state === stepState.completed;

    stepInfo.transcoding.state === stepState.in_progress;

    if (transcodingIsCompleted) return "Completed";
    else if (cleaningIsCompleted && ConsoOrLaterIsRunning) return "In Progress";
    else return "Pending";

    return "";
  }

  step_info: stepsInformation = {} as stepsInformation;

  calculateStepsInfo() {
    console.log("calculating step_info");
    if (!this.duration_info) return {} as stepsInformation;

    const newSteps = {} as Record<string, any>;

    for (const step of steps_list) {
      console.log("step = ", this.duration_info);
      let current_duration = 0;
      if (step !== "collection") {
        const current_duration_info = this.duration_info[step];
        let state: stepState | undefined;
        if (!current_duration_info) {
          state = stepState.pending;

          newSteps[step] = {
            duration: "",
            state: state,
          };
          continue;
        }

        let created_at = current_duration_info["created_at"] as
          | string
          | undefined;
        let updated_at = current_duration_info["updated_at"] as
          | string
          | undefined;

        const created_at_date =
          created_at !== null &&
          created_at !== undefined &&
          created_at !== "Unknown"
            ? moment(created_at).toDate()
            : undefined;
        const updated_at_date =
          updated_at !== null &&
          updated_at !== undefined &&
          updated_at !== "Unknown"
            ? moment(updated_at).toDate()
            : undefined;

        if (
          this.isValidDate(created_at_date) &&
          this.isValidDate(updated_at_date)
        ) {
          state = stepState.completed;
          if (created_at_date && updated_at_date)
            current_duration = Math.floor(
              Math.abs(created_at_date.valueOf() - updated_at_date.valueOf()),
            );
        } else if (this.isValidDate(created_at_date)) {
          state = stepState.in_progress;

          if (created_at_date)
            current_duration = Math.floor(
              Math.abs(
                created_at_date.valueOf() -
                  convertDateToUTC(new Date()).valueOf(),
              ),
            );
        } else {
          state = stepState.pending;
        }

        newSteps[step] = {
          duration: current_duration,
          state: state,
        };
      } else {
        newSteps[step] = {
          duration: 0,
          state: stepState.completed,
        };
      }
    }

    return newSteps as stepsInformation;
  }

  fix_step_name(step: string) {
    if (step === "categorization") return "categorizing";
    else if (step === "transcoding_pg") return "transcoding";
    else return step;
  }

  capitalize(string: string) {
    if (string === undefined) {
      return "";
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  notificationFilterChanged() {
    const today = new Date();

    let date_filter = null;
    switch (this.notifications_time_filter) {
      case "today":
        date_filter = (notification: any) =>
          moment(today).isSame(notification.date, "day");
        break;
      case "yesterday":
        date_filter = (notification: any) =>
          this.isYesterday(notification.date);
        break;
      case "this_week":
        date_filter = (notification: any) =>
          moment(today).isSame(notification.date, "week");
        break;
      case "this_month":
        date_filter = (notification: any) =>
          moment(today).isSame(notification.date, "month");
        break;
    }

    return;
    if (date_filter) {
      this.notifications = this.initial_notifications.filter(date_filter);
      console.log(this.notifications);
    }
  }

  isYesterday(dateToCheck: Date) {
    const yesterday = moment().subtract(1, "days").toDate();

    return (
      dateToCheck.getFullYear() === yesterday.getFullYear() &&
      dateToCheck.getMonth() === yesterday.getMonth() &&
      dateToCheck.getDate() === yesterday.getDate()
    );
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDate(date: Date) {
    return `${this.padNumber(date.getDate())}.${this.padNumber(
      date.getMonth() + 1,
    )}.${date.getFullYear()} ${this.getHHMM(date)}`;
  }

  getHHMM(date: Date) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  padNumber(number: number) {
    return String(number).padStart(2, "0");
  }

  getStepColor(notification: any) {
    const stepToColor = {
      collection: "grey",
      cleaning: "grey",
      consolidation: "#f59c4a",
      referencing: "#f59c4a",
      recoding: "#f59c4a",
      transcoding: "green",
    } as Record<string, string>;

    if (notification.raw_data && notification.raw_data.step) {
      const step = notification.raw_data.step;
      return stepToColor[step];
    }

    return "";
  }

  getStageColor(stage: stages | undefined) {
    switch (stage) {
      case stages.Pending:
        return "#8D48FD";
      case stages.Completed:
        return "#35BA55";
      case stages.InActive:
        return "#8E9397";
      default:
        return "#F59C4A";
    }
  }

  getStageLabel(stage: stages) {
    switch (stage) {
      case stages.Pending:
        return "Pending";
      case stages.Collection:
        return "Collection";
      case stages.Cleaning:
        return "Cleaning";
      case stages.Consolidation:
        return "Consolidation";
      case stages.Recoding:
        return "Recoding";
      case stages.Categorizing:
        return "Categorizing";
      case stages.Transcoding:
        return "Transcoding";
      case stages.Completed:
        return "Completed";
      case stages.InActive:
        return "Inactive";
      default:
        return "";
    }
  }
  getStageIcon(stage: stages) {
    switch (stage) {
      case stages.Pending:
        return "pending.svg";
      case stages.Collection:
        return "collection.svg";
      case stages.Cleaning:
        return "cleaning.svg";
      case stages.Consolidation:
        return "consolidation.svg";
      case stages.Recoding:
        return "recording.svg";
      case stages.Referencing:
        return "referencing.svg";
      case stages.Categorizing:
        return "categorizing.svg";
      case stages.Transcoding:
        return "transcoding.svg";
      case stages.Completed:
        return "completed.svg";
      case stages.InActive:
        return "inactive.svg";
      default:
        return "";
    }
  }
}

export const steps_list = [
  "collection",
  "cleaning",
  "consolidation",
  "referencing",
  "recoding",
  "categorization",
  "transcoding",
];
