import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Signal,
  signal,
  WritableSignal,
  computed,
} from "@angular/core";
import { computeChartSettings } from "../bars-chart/bars_chart_utils";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-attributes-charts",
  templateUrl: "./attributes-charts.component.html",
  styleUrls: ["./attributes-charts.component.scss"],
})
export class AttributesChartsComponent implements OnInit {
  current_records_chart_options: Signal<Record<string, any>> = computed(() => {
    let new_options = cloneDeep(
      this.get_attributes_charts_options(this._current_records_data()),
    );

    return new_options;
  });

  unique_values_chart_options: Signal<Record<string, any>> = computed(() => {
    return cloneDeep(
      this.get_attributes_charts_options(this._unique_values_data()),
    );
  });

  undefined_values_chart_options: Signal<Record<string, any>> = computed(() => {
    return cloneDeep(
      this.get_attributes_charts_options(this._percent_blanks_data()),
    );
  });

  attributes_charts_options = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          padding: 14,
        },
        grid: {
          display: false,
          drawTicks: false,
          ticks: {
            color: "#8E9397",
            font: {
              size: 12,
              family: "Satoshi",
              weight: 500,
            },
          },
        },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 1500,
        border: {
          display: false,
        },
        ticks: {
          stepSize: 10000,
          callback: (value: number, index: number, ticks: any) => {
            //TODO apply this formatter where it's appropriate

            const formatter = new Intl.NumberFormat("fr-FR", {
              style: "decimal",
              useGrouping: true,
            });

            return formatter.format(value);
          },
          color: "#8E9397",
          crossAlign: "far",
          font: {
            size: 12,
            family: "Satoshi",
            weight: 500,
          },
        },
      },
    },
  };

  _current_records_data: WritableSignal<Record<string, any>> = signal({});
  _unique_values_data: WritableSignal<Record<string, any>> = signal({});
  _percent_blanks_data: WritableSignal<Record<string, any>> = signal({});

  @Input() set percent_blanks_data(data: Record<string, any>) {
    this._percent_blanks_data.update((x: any) => ({ ...data }));
  }

  @Input() set current_records_data(data: Record<string, any>) {
    this._current_records_data.update((x: any) => ({ ...data }));
  }

  @Input() set unique_values_data(data: Record<string, any>) {
    this._unique_values_data.update((x: any) => ({ ...data }));
  }

  @Input() field_name = "Product";
  @Input() showByUpdate = true;

  ngOnInit() {}

  get_attributes_charts_options(current_chart: Record<string, any>) {
    if (
      current_chart &&
      current_chart["datasets"] &&
      current_chart["datasets"].length > 0
    ) {
      const actual_data = current_chart["datasets"][0]["data"];
      const config = computeChartSettings(actual_data);
      return this.setStepSize(config.step_size, config.max, actual_data);
    }

    return { ...this.attributes_charts_options };
  }

  jsonify(obj: any) {
    return JSON.stringify(obj);
  }

  setStepSize(stepSize: number, max: number, data: number[]) {
    const new_config = { ...this.attributes_charts_options };

    console.log("new step size is ", stepSize);
    new_config.scales.y.ticks = {
      ...new_config.scales.y.ticks,
      stepSize: stepSize,
    };
    new_config.scales.y.suggestedMax = max;

    return { ...new_config };
  }

  capitalize(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export function buildYaxisRenderingLambda(data: number[]) {
  const default_function = (value: number, index: number, ticks: any) => value;
  if (data.length === 0) return default_function;

  const powersToStrings = {
    "0": "",
    "3": "K",
    "6": "M",
    "9": "B",
    "12": "T",
  } as Record<string, string>;

  const commonUnitPower = getCommonUnit(data);

  if (!Object.keys(powersToStrings).includes(commonUnitPower.toString())) {
    console.warn(
      "power unit = ",
      commonUnitPower,
      " Doesn't exist in mapping!",
    );
    return default_function;
  }

  const string_unit = powersToStrings[commonUnitPower.toString()];
  console.log("string unit us ", string_unit);

  const powerToTheTen = Math.pow(10, commonUnitPower);

  let result = (value: number, index: number, ticks: any) => {
    return (value / powerToTheTen).toFixed(0) + string_unit;
  };

  return result;
}

function mostRepeatedNumbers(nums: number[]) {
  const unique_nums = Array.from(new Set(nums));

  if (unique_nums.length === 1) return unique_nums;

  const counter = {} as Record<string, number>;

  //Init counter
  unique_nums.map((unique_num: number) => {
    counter[unique_num.toString()] = 0;
  });

  unique_nums.map((num: number) => {
    counter[num.toString()]++;
  });

  const max = Math.max(
    ...Object.keys(counter).map((string_num: string) => parseInt(string_num)),
  );

  const maximums = Object.keys(counter)
    .filter((string_num: string) => parseInt(string_num) === max)
    .map((string_num: string) => parseInt(string_num));

  console.log("maximums are ", maximums);

  return maximums;
}

function getPowerOfTen(num: number) {
  return num.toString().length - 1;
}

function biggestMultipleOfThree(N: number) {
  // Start from the largest multiple of 3 less than or equal to N
  let multiple = N - (N % 3);

  // If N is already a multiple of 3, return it
  if (multiple === N) {
    return N;
  }

  return multiple;
}

function getCommonUnit(data: number[]) {
  let powers = data.map((num: number) => getPowerOfTen(num));

  const allNumbersAreNotMultiplesOfThree = powers.every(
    (power: number) => power % 3 !== 0,
  );

  if (allNumbersAreNotMultiplesOfThree) {
  }

  //Only keep multipliers of 3
  //powers = powers.filter((power: number) => power % 3 === 0);

  let mostRepeatedPowers = mostRepeatedNumbers(powers);

  mostRepeatedPowers = mostRepeatedPowers.map((power: number) =>
    biggestMultipleOfThree(power),
  );

  if (mostRepeatedPowers.length === 1) {
    return mostRepeatedPowers[0];
  } else {
    //If many powers are maximum (ex: 2 in thousands and 2 in millions and count is 5): return the smallest one
    return Math.min(...mostRepeatedPowers);
  }
}
