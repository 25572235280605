import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-place-holder",
  templateUrl: "./place-holder.component.html",
  styleUrls: ["./place-holder.component.scss"],
})
export class PlaceHolderComponent implements OnInit {
  myForm: FormGroup;
  scopes_of_subscription = [{ value: "a", label: "a" }];

  data = { datasets: [{ data: [1] }], labels: ["AA"] } as Record<
    string,
    Record<string, any>[] | any[]
  >;

  data2 = { datasets: [{ data: [100 * 100] }], labels: ["AA"] } as Record<
    string,
    Record<string, any>[] | any[]
  >;
  constructor(private fb: FormBuilder) {
    this.myForm = this.fb.group({
      scope_of_subscription: [
        {
          is_other: true,
          value: ["dddd"],
        },
      ],
    });
    this.myForm.controls["scope_of_subscription"].setValue({
      is_other: true,
      value: ["dddd"],
    });
  }

  ngOnInit() {}

  submit() {
    alert(JSON.stringify(this.myForm.value));
  }
}
