import { Component, EventEmitter, Output } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Source } from "src/app/core/models/source";
import { getCountryName } from "src/app/core/models/country_codes";
import { getPanelGroupLabel } from "src/app/landing/my/filters-data";
import { SourceService } from "src/app/core/services/source.service";
import { fixSourceObjects } from "src/app/data-source-dialog/data-source-dialog.component";

@Component({
  selector: "new-data-source-dialog",
  templateUrl: "./new-data-source-dialog.component.html",
  styleUrls: ["./new-data-source-dialog.component.scss"],
})
export class NewDataSourceDialogComponent {
  source: Source | null = null;
  selectedFile: File | null = null;
  isValid = true;
  formSubmitted = false;

  constructor(
    private sourceService: SourceService,
    private confirmationDialog: ConfirmationService,
  ) {}

  reload() {
    this.formSubmitted = false;
    this.isValid = true;
  }

  sourceInformationUpdated(source: Source) {
    console.log("new source info", source);
    this.source = { ...source };
  }

  validationStateChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  updateSelectedFile(newFile: File | null) {
    this.selectedFile = newFile;
  }

  uploadFileName() {
    if (
      this.source &&
      this.source.country &&
      this.source.end_period &&
      this.source.panel_group
    ) {
      const countryLabel = getCountryName(this.source.country);
      const panelLabel = getPanelGroupLabel(this.source.panel_group);
      const endPeriodText = "";

      console.log("end period is ", this.source?.end_period);
      let endPeriod = undefined;
      if (this.source.end_period) endPeriod = new Date(this.source.end_period);
      else endPeriod = new Date();

      if (this.source)
        return `MINE-${countryLabel}-${panelLabel}-${this.formatEndPeriod(endPeriod)}-Raw.csv`;
      return "";
    }

    return "";
  }

  formatEndPeriod(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Pad month with leading zero if needed
    return `${year}${month}`;
  }

  submitData() {
    this.formSubmitted = true;
    if (this.isValid) {
      if (this.source) {
        const sourceToSubmit = this.source;
        if (!this.source) return;

        //@TODO check is this OK: to set a new source to pending state
        this.source.state = "pending";

        if (sourceToSubmit.current_update) {
          this.source.current_update = this.formatDate(
            new Date(sourceToSubmit.current_update),
          );
        } else sourceToSubmit.current_update = undefined;

        if (sourceToSubmit.end_period)
          this.source.end_period = this.formatDate(
            new Date(sourceToSubmit.end_period),
          );
        else sourceToSubmit.end_period = undefined;

        if (sourceToSubmit.next_update)
          this.source.next_update = this.formatDate(
            new Date(sourceToSubmit.next_update),
          );
        else this.source.next_update = undefined;

        if (sourceToSubmit.start_date)
          this.source.start_date = this.formatDate(
            new Date(sourceToSubmit.start_date),
          );
        else sourceToSubmit.start_date = undefined;

        //TODO maybe remove this with new s3 upload
        //sourceToSubmit.data_upload = this.selectedFile;

        this.sourceService
          .createSource(this.packFormData(this.source))
          .subscribe((res) => {
            this.sourceService.notifySourceUpdate();
            this.closeDialog();
          });
      }
    } else {
      this.formSubmitted = true;
    }
  }

  packFormData(formValues: Record<string, any>) {
    const attributesWithOtherValues = [
      "formulation_class_type",
      "update_frequency",
    ];

    //List of attributes that support "other values" but their other values should be persisted

    const newFormValues = {} as Record<string, any>;
    Object.keys(formValues).map((attribute: string) => {
      if (["start_date", "current_update", "next_update"].includes(attribute))
        return;
      if (formValues[attribute] === null) {
        newFormValues[attribute] = null;
        return;
      } else if (
        attribute === "scope_of_subscription" &&
        formValues["scope_of_subscription"] !== null
      ) {
        newFormValues[attribute] = formValues[attribute].value;
      } else if (attributesWithOtherValues.includes(attribute)) {
        newFormValues[attribute] = JSON.stringify(formValues[attribute]);
      } else {
        if (formValues[attribute].value !== undefined)
          newFormValues[attribute] = formValues[attribute].value;
        else newFormValues[attribute] = formValues[attribute];
      }
    });

    return newFormValues;
  }
  closeDialog() {
    this.confirmationDialog.close();
  }

  formatDate(date: Date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T00:00`;
  }
}
